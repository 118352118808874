export class UserModel {

  id: string;
  name: string;
  lastName: string;
  uid: string;
  token: string;
  email: string;
  tienda: string;
  firebaseUser: any;

  constructor() {
    this.id = '';
    this.name = '';
    this.lastName = '';
    this.email = '';
    this.uid = '';
    this.tienda = '';
    this.token = '';
  }
}
