import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable, of} from 'rxjs';
import {leftJoin} from '../../utils/collectionJoin';
import {catchError, map} from 'rxjs/operators';
import {UserModel} from '../../models/user-model';
import {DndDropEvent} from 'ngx-drag-drop';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";

polyfill( {
  // use this to make use of the scroll behaviour
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
} );

// workaround to make scroll prevent work in iOS Safari > 10

  window.addEventListener( "touchmove", function() { }, { passive: false } );


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  pedidos: any[] ;
  productos: any[];
  usuario: UserModel;
  pedidoDisplay: any;
  multiSesionDisplay: any;
  pedidoDragging: any;
  time: Date;
  metodos: {} = {1: 'Efectivo', 2: 'Datáfono'};
  //url = 'http://localhost:8081/';
  url = 'https://us-central1-oxxo-dev.cloudfunctions.net/b2b/';
  //url = 'https://us-central1-oxxo-prod-47b45.cloudfunctions.net/b2b/';
  endpoint_pedidos = 'list/pedido';

  constructor(private userService: UserService,
              private router: Router,
              private db: AngularFirestore,
              private http: HttpClient,
              private ngxLoader: NgxUiLoaderService) {
    this.pedidoDisplay = null;
    this.multiSesionDisplay = null;

  }

  listenLoggin(id){
    this.db.collection("logged_users").doc(id+"")
        .snapshotChanges().subscribe((doc:any) => {
            console.log("Current data: ", doc.payload.data());
            if(doc.payload.data() && parseFloat(doc.payload.data().fecha) != parseFloat(localStorage.getItem('fecha'))){
                this.multiSesionDisplay = true;
                console.log('new log')
                localStorage.setItem('fecha',doc.payload.data().fecha)
            }
        });
  }

  ngOnInit() {
    setInterval(() => {
      this.time = new Date();
    }, 1000);
    this.ngxLoader.start();
    this.userService.getCurrentUser().then((usr) => {
      this.usuario = usr;
      //this.usuario.tienda = "50RBT";
      console.log(this.usuario,this.usuario.tienda,"tienda_"+this.usuario.tienda);
      this.db.collection('setting', ref => ref.where('name', '==', "tienda_"+this.usuario.tienda)).snapshotChanges().subscribe(value => {this.loadCompras()});
      if(!localStorage.getItem('fecha')){
          var fecha = new Date();
          localStorage.setItem('fecha',fecha.getTime()+"")
          this.db.collection('logged_users').doc(usr.id+"").set({
            fecha: fecha.getTime()
        })
        .then(() => {
            console.log("Login reportado");
            this.listenLoggin(usr.id);
        })
        .catch((error) => {
            //console.error("Error writing document: ", error);
        });
      }else{
        this.listenLoggin(usr.id);
      }


    }).catch(err => {
      console.log("errr",err);
      this.ngxLoader.stop();
      this.router.navigate(['/login']);
    });

  }

  loadCompras(){
    this.pedidos = [];
    var url = this.url+this.endpoint_pedidos+'?id_tienda='+this.usuario.tienda;
    url = 'https://adminpruebas.oxxodomicilios.com/index.php/api/pedidos_tienda/' +this.usuario.tienda;//prod
    
    url = 'https://admin.oxxodomicilios.com/index.php/api/pedidos_tienda/'+this.usuario.tienda;//dev
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'ApiKey bWF1cmljaW9AaXJpZGlhbi5jbzo0QTJGOEI4Mi1CNkQ4LTQyNTgtQjIzRC0xQjg1MzQzMkQ2Njk'
    });
    console.log("url",url);
    this.http.get(url, {headers}).subscribe((data) => {
      console.log("que ",data);
      var data_arr : any = data;
      console.log('pedidos not: ',data);
      data_arr.forEach(compra => {
        console.log("compra",compra.id,compra.estado_id);
        if(compra.productos){
          let items = compra.productos.split(";");
          //console.log('prods',compra.productos,items);
          compra.compra_item = [];
          items.forEach(item => {
            let elems = item.split("&");
            if(elems.length>3){
              const producto = elems[0];
              const cantidad = elems[1];
              const toppings = elems[2];
              const precio = elems[3];
              const imagen = elems[4];
              const obj_c = {"producto":producto,"cantidad":cantidad,"toppings":toppings,"precio":precio,"imagen":imagen};
              compra.compra_item.push(obj_c);
            }
          });
          
          if(compra.status_mu){
            if(compra.status_mu != 1 && (compra.status_mu - 1) > compra.estado_id){
              compra.estado_id = compra.status_mu - 1;
            }
          }
        }
        this.pedidos.push(compra);
      });

      console.log('pedidos 2: ',this.pedidos);
      //this.pedidos = data;
      this.ngxLoader.stop();
    }, err => {
      console.error(err);
      //reject(err.error);
    });
  }

  findProduct(id) {
    const filter = this.productos.filter(function(value) {
      return value.id === id;
    });
    return filter && filter.length === 1 ? filter[0] : {};
  }

  logout() {
    this.userService.logout().then(() => {
      // window.location.reload();
      localStorage.removeItem('fecha')
      this.router.navigate(['/login']);
    });

  }

  timeElapsed(date1) {
    // @ts-ignore
    var dobj = new Date();
    dobj.setHours(dobj.getHours() + 5);
    const msec = dobj.getTime() - Date.parse(date1);
    const mins = Math.floor(msec / 60000);
    const hrs = Math.floor(mins / 60);
    const days = Math.floor(hrs / 24);
    const yrs = Math.floor(days / 365);
    return mins;
    // append(dl, 'In minutes: ', mins + ' minutes');
    // mins = mins % 60;
    // append(dl, 'In hours: ', hrs + ' hours, ' + mins + ' minutes');
    // hrs = hrs % 24;
    // append(dl, 'In days: ', days + ' days, ' + hrs + ' hours, ' + mins + ' minutes');
    // days = days % 365;
    // append(dl, 'In years: ', yrs + ' years ' + days + ' days ');
  }

  onDragStart(event: DragEvent, pedido?) {
    this.pedidoDragging = pedido;
    console.log('drag started: ', JSON.stringify(event, null, 2), 'pedido: ', pedido);
  }

  onDragEnd(event: DragEvent, pedido?) {
    this.pedidoDragging = null;
    // console.log('drag ended: ', JSON.stringify(event, null, 2), 'pedido: ', pedido);
  }

  onDrop(event: DndDropEvent, estadoId) {

    this.pedidoDragging = null;
    const pedidoId = event.data;
    console.log('dropped', JSON.stringify(event, null, 2));
    console.log('Actualizando pedido #' + pedidoId + ' --> ' + estadoId);
    const url = `${environment.API_URL}pedidos/${pedidoId}`;
    //this.ngxLoader.start();
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser().then((usr) => {
        this.usuario = usr;
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${usr.token}`
        });
        this.http.patch(url, {estado: estadoId}, {headers}).subscribe((data) => {
          resolve(data);
        }, err => {
          console.error(err);
          reject(err.error);
        });
      }).catch(reject);
    }).catch((err) => {
      console.error(err);
     // this.ngxLoader.stop();
    });
  }

  imprimir(){
    window.print();
  }

  cerrarMultiSesion(){
    this.multiSesionDisplay = null;
  }

}
