import {Component, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import firebase from 'firebase/app';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  error: { email: null, password: null };
  showEye: boolean;

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private router: Router) {
    this.error = {
      email: null,
      password: null
    };
    this.showEye = false;
  }

  ngOnInit() {
    // this.userService.getCurrentUser().then(console.log);
  }

  doLogin() {
   
    this.userService.login(this.email, this.password)
      .then((data) => {
        this.router.navigate(['/dashboard']);
      }).catch(async (err) => {
          this.password = '';
        
     });
  }

  clearError(field) {
    if (this.error) {
      this.error[field] = null;
    }
  }

}
