import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {LoginComponent} from './pages/login/login.component';
import {FormsModule} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {environment} from '../environments/environment';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './guards/auth.guard';
import {UserService} from './services/user.service';
import {DndModule} from 'ngx-drag-drop';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {registerLocaleData} from '@angular/common';

import localeEsCo from '@angular/common/locales/es-CO';

registerLocaleData(localeEsCo, 'es-CO');

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.fire),
    AngularFirestoreModule,
    HttpClientModule,
    AngularFirestoreModule,
    DndModule,
    NgxUiLoaderModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'es-CO'},
    AngularFireAuth,
    HttpClient,
    UserService,
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
