import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'oxxo-tienda';
  constructor(private _elementRef: ElementRef) {
  }

  ngOnInit(): void {
      this._elementRef.nativeElement.removeAttribute("ng-version");
  }
}
