import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {AngularFireAuth} from '@angular/fire/auth';
import {HttpClient} from '@angular/common/http';
import {UserModel} from '../models/user-model';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class UserService {


  constructor(
    private afAuth: AngularFireAuth,
    private http: HttpClient
  ) {
  }

  private getFosUser(uid, idToken) {
    return new Promise((resolve, reject) => {
      const url = environment.API_URL + 'auth/login';
      this.http.post(url, {uid, idToken, rol: 'ROLE_POS_ADMIN'}).subscribe((data) => {
        resolve(data);
      }, err => {
        reject(err.error);
      });
    });
  }

  login(email, password) {
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(email, password).then((res) => {
        // console.log(res);
        const user = res.user;
        user.getIdToken().then((idToken) => {
          this.getFosUser(user.uid, idToken).then(resolve).catch(reject);
        });

      }).catch(async (err) => {
        await firebase.auth().signOut();
        reject(err);
      });
    });
  }


  getCurrentUser(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        console.log('getCurrentUser: ', user);
        const userModel = new UserModel();
        if (user) {
          user.getIdToken().then(token => {
            userModel.firebaseUser = user;
            userModel.email = user.email;
            userModel.uid = user.uid;
            userModel.token = token;
            this.getFosUser(user.uid, token).then((data) => {
              if (data) {
                // @ts-ignore
                userModel.id = data.id;
                // @ts-ignore
                userModel.name = data.nombre;
                // @ts-ignore
                userModel.lastName = data.apellido;
                // @ts-ignore
                userModel.tienda = data.tienda_id;
              }
              resolve(userModel);
            }).catch(reject);
          });
        } else {
          reject({message: 'No user logged in'});
        }
      });
    });
  }

  // Returns true if user is logged in
  async authenticated(): Promise<boolean> {
    return await new Promise(resolve => {
      this.getCurrentUser()
        .then(() => resolve(true))
        .catch(() => resolve(false));
    });
  }

  logout() {
    return new Promise(((resolve, reject) => {
      firebase.auth().signOut().then(() => resolve(true)).catch(reject);
    }));
  }
}
