<header>
  <img src="assets/images/OXXO_logo.svg" alt="OXXO" class="logo">
  <div class="info">
    <button (click)="logout()">Cerrar sesión</button>
    <h3>ENCARGADO: <span id="nombreEncargado" *ngIf="usuario">{{usuario.name}} {{usuario.lastName}}</span></h3>
  </div>

</header>
<div class="fechaActual">
  <div id="fecha">
    {{ time | date:'fullDate' }}
  </div>
  <div id="hora">
    {{ time | date:'hh:mm:ss a' }}
  </div>
</div>
<div class="contColumnas">
  <div class="columna" id="demorados">
    <h4 class="tituloCol">PEDIDOS DEMORADOS</h4>
    <div class="contPedidos" *ngFor="let pedido of pedidos">
      <div class="pedido"
           *ngIf="pedido.estado_id == 5"
           [dndDraggable]="pedido.id"
           [dndEffectAllowed]="'move'"
           (dndStart)="onDragStart($event, pedido)"
           (dndEnd)="onDragEnd($event, pedido)">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">{{pedido.nombre}} {{pedido.apellido}}</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span
            class="direccionPedido">{{pedido.direccion.direccion}}</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#{{pedido.id}}</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span
            class="nombrePedido">Hace {{timeElapsed(pedido.fecha_real_procesamiento)}} minutos</span></h5>
        </div>
        <div class="acciones">
          <button (click)="this.pedidoDisplay = pedido;">Ver pedido</button>
          <!--<a href="#" onclick="cancelarPedido()">Cancelar pedido</a>-->
        </div>
      </div>
      <!--
      <div class="pedido cambio">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">Juan Esteban Díaz Plazas</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span class="direccionPedido">Cra 17a # 113 - 62 apt 201</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#123654</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span class="nombrePedido">Hace 1 minuto</span></h5>
        </div>
        <div class="acciones">
          <a href="#" onclick="verPedido()">Ver pedido</a>
          <a href="#" onclick="cancelarPedido()">Cancelar pedido</a>
        </div>
      </div>
      -->
    </div>
  </div>

  <div class="columna" id="noLeidos">
    <h4 class="tituloCol">PEDIDOS NO LEÍDOS</h4>
    <div class="contPedidos" *ngFor="let pedido of pedidos">
      <div class="pedido"
           *ngIf="pedido.estado_id == 1"
           [dndDraggable]="pedido.id"
           [dndEffectAllowed]="'move'"
           (dndStart)="onDragStart($event, pedido)"
           (dndEnd)="onDragEnd($event, pedido)">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">{{pedido.nombre}} {{pedido.apellido}}</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span
            class="direccionPedido">{{pedido.direccion.direccion}}</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#{{pedido.id}}</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span
            class="nombrePedido">Hace {{timeElapsed(pedido.fecha_real_procesamiento)}} minutos</span></h5>
        </div>
        <div class="acciones">
          <button (click)="this.pedidoDisplay = pedido;">Ver pedido</button>
          <!--<a href="#" onclick="cancelarPedido()">Cancelar pedido</a>-->
        </div>
      </div>
      <!--
      <div class="pedido cambio">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">Juan Esteban Díaz Plazas</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span class="direccionPedido">Cra 17a # 113 - 62 apt 201</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#123654</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span class="nombrePedido">Hace 1 minuto</span></h5>
        </div>
        <div class="acciones">
          <a href="#" onclick="verPedido()">Ver pedido</a>
          <a href="#" onclick="cancelarPedido()">Cancelar pedido</a>
        </div>
      </div>
      -->
    </div>
  </div>
  <div class="columna" id="alistamiento"
       dndDropzone
       (dndDrop)="onDrop($event, 2)"
       [dndDragoverClass]="'overlayYes'"
       [dndDropzoneDisabledClass]="'_overlayNo'"
       [dndDisableIf]="pedidoDragging && (pedidoDragging.estado_id != 1 && pedidoDragging.estado_id != 5 )">
    <h4 class="tituloCol">PEDIDOS EN ALISTAMIENTO</h4>

    <div class="contPedidos" *ngFor="let pedido of pedidos">
      <div class="pedido"
           *ngIf="pedido.estado_id == 2"
           [dndDraggable]="pedido.id"
           [dndEffectAllowed]="'move'"
           (dndStart)="onDragStart($event, pedido)"
           (dndEnd)="onDragEnd($event, pedido)">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">{{pedido.nombre}} {{pedido.apellido}}</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span
            class="direccionPedido">{{pedido.direccion.direccion}}</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#{{pedido.id}}</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span
            class="nombrePedido">Hace {{timeElapsed(pedido.fecha_real_procesamiento)}} minutos</span></h5>
        </div>
        <div class="acciones">
          <button (click)="this.pedidoDisplay = pedido;">Ver pedido</button>
          <!--<a href="#" onclick="cancelarPedido()">Cancelar pedido</a>-->
        </div>
      </div>
      <!--
      <div class="pedido cambio">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">Juan Esteban Díaz Plazas</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span class="direccionPedido">Cra 17a # 113 - 62 apt 201</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#123654</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span class="nombrePedido">Hace 1 minuto</span></h5>
        </div>
        <div class="acciones">
          <a href="#" onclick="verPedido()">Ver pedido</a>
          <a href="#" onclick="cancelarPedido()">Cancelar pedido</a>
        </div>
      </div>
      -->
    </div>
  </div>
  <div class="columna" id="enCamino"
       dndDropzone
       (dndDrop)="onDrop($event, 3)"
       [dndDragoverClass]="'overlayYes'"
       [dndDropzoneDisabledClass]="'_overlayNo'"
       [dndDisableIf]="pedidoDragging && pedidoDragging.estado_id != 2">
    <h4 class="tituloCol">PEDIDOS EN CAMINO</h4>
    <div class="contPedidos" *ngFor="let pedido of pedidos">
      <div class="pedido"
           *ngIf="pedido.estado_id == 3"
           [dndDraggable]="pedido.id"
           [dndEffectAllowed]="'move'"
           (dndStart)="onDragStart($event, pedido)"
           (dndEnd)="onDragEnd($event, pedido)">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">{{pedido.nombre}} {{pedido.apellido}}</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span
            class="direccionPedido">{{pedido.direccion.direccion}}</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#{{pedido.id}}</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span
            class="nombrePedido">Hace {{timeElapsed(pedido.fecha_real_procesamiento)}} minutos</span></h5>
        </div>
        <div class="acciones">
          <button (click)="this.pedidoDisplay = pedido;">Ver pedido</button>
        </div>
      </div>
      <!--
      <div class="pedido cambio">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">Juan Esteban Díaz Plazas</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span class="direccionPedido">Cra 17a # 113 - 62 apt 201</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#123654</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span class="nombrePedido">Hace 1 minuto</span></h5>
        </div>
        <div class="acciones">
          <a href="#" onclick="verPedido()">Ver pedido</a>
          <a href="#" onclick="cancelarPedido()">Cancelar pedido</a>
        </div>
      </div>
      -->
    </div>
  </div>
  <div class="columna" id="entregados"
       dndDropzone
       (dndDrop)="onDrop($event, 4)"
       [dndDragoverClass]="'overlayYes'"
       [dndDropzoneDisabledClass]="'_overlayNo'"
       [dndDisableIf]="pedidoDragging && pedidoDragging.estado_id != 3">
    <h4 class="tituloCol">PEDIDOS ENTREGADOS</h4>

    <div class="contPedidos" *ngFor="let pedido of pedidos">
      <div class="pedido"
           *ngIf="pedido.estado_id == 4">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">{{pedido.nombre}} {{pedido.apellido}}</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span
            class="direccionPedido">{{pedido.direccion.direccion}}</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#{{pedido.id}}</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span
            class="nombrePedido">Hace {{timeElapsed(pedido.fecha_real_procesamiento)}} minutos</span></h5>
        </div>
        <div class="acciones">
          <button (click)="this.pedidoDisplay = pedido;">Ver pedido</button>
        </div>
      </div>
      <!--
      <div class="pedido cambio">
        <div class="resumen">
                        <span class="alert">
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
          <h5><span class="tituloFila">PEDIDO A NOMBRE DE: </span><span
            class="nombrePedido">Juan Esteban Díaz Plazas</span></h5>
          <h5><span class="tituloFila">Dirección de Entrega: </span><span class="direccionPedido">Cra 17a # 113 - 62 apt 201</span>
          </h5>
          <h5><span class="tituloFila">Número de pedido: </span><span class="numeroPedido">#123654</span></h5>
          <h5><span class="tituloFila">Hora de pedido: </span><span class="nombrePedido">Hace 1 minuto</span></h5>
        </div>
        <div class="acciones">
          <a href="#" onclick="verPedido()">Ver pedido</a>
          <a href="#" onclick="cancelarPedido()">Cancelar pedido</a>
        </div>
      </div>
      -->
    </div>
  </div>
</div>
<div class="modal modalResumen" [ngStyle]="{'display': !pedidoDisplay ? 'none' : 'block'}">
  <div class="modalInterno" *ngIf="pedidoDisplay">
    <div class="cabezaModal">
      <a (click)="imprimir()" class="printModal"><i class="fas fa-print"></i></a>
      <div class="titulo">
        <i class="fas fa-exclamation-triangle alert"></i>
        <h2>Resumen pedido</h2>
      </div>
      <button (click)="pedidoDisplay = null;" class="closeModal">
        <i class="far fa-times-circle"></i>
      </button>
    </div>
    <!--<img src="assets/images/OXXO_logo.svg" alt="" class="logo">-->
    <div class="infoPedido">
      <h5>
        <span class="tituloFila">PEDIDO A NOMBRE DE: </span><span class="nombrePedidoModal">{{pedidoDisplay.nombre}} {{pedidoDisplay.apellido}}</span>
      </h5>
      <h5>
        <span class="tituloFila">Celular: </span><span class="nombrePedidoModal">{{pedidoDisplay.telfono_cliente}}</span>
      </h5>
      <h5>
        <span class="tituloFila">Dirección de Entrega: </span><span class="direccionPedidoModal">{{pedidoDisplay.direccion}}</span>
      </h5>
      <h5>
        <span class="tituloFila">Número de pedido: </span><span class="numeroPedidoModal">#{{pedidoDisplay.id}}</span>
      </h5>
      <h5>
        <span class="tituloFila">Hora de pedido: </span><span class="nombrePedidoModal">Hace {{timeElapsed(pedidoDisplay.fecha_real_procesamiento)}} minutos</span>
      </h5>
      <h5>
        <span class="tituloFila">Método: </span><span class="numeroPedidoModal">{{metodos[pedidoDisplay.metodo_id]}}</span>
      </h5>
      <h5 *ngIf="pedidoDisplay.metodo_id == 1">
        <span class="tituloFila">Paga con: </span><span class="numeroPedidoModal">${{pedidoDisplay.paga_con | number}}</span>
      </h5>
    </div>
    <div class="resumenPedido">
      <div class="elemento" *ngIf="pedidoDisplay.factura_electronica">
        <img src="https://firebasestorage.googleapis.com/v0/b/oxxo-prod-47b45.appspot.com/o/app_images%2Ffe-02.jpg?alt=media&token=e8a515c4-d977-4d2d-9de7-c8f21c769df1" alt="">
        <div class="titulo">
          <h3>{{ pedidoDisplay.factura_electronica }}</h3>
        </div>
        <div class="cantidad">
          <span>1</span>
        </div>
        <div class="precio">
          <span>$0</span>
        </div>
      </div>
      <div class="elemento" *ngFor="let item of pedidoDisplay.compra_item">
        <img src="{{item.imagen}}" alt="">
        <div class="titulo">
          <h3>{{item.producto}}</h3>
          <h4>{{item.toppings}}</h4>
        </div>
        <div class="cantidad">
          <span>{{item.cantidad}}</span>
        </div>
        <div class="precio">
          <span>${{item.precio | number}}</span>
        </div>
      </div>

    </div>
    <div class="preciosTotales">
      
      <div class="valorDom">
        <span>Valor domicilio: ${{pedidoDisplay.costo_domicilio | number}}</span>
      </div>
      <div class="descuento">
        <span>Descuento: $0</span><br>
        <span *ngIf="pedidoDisplay.cupon_id">Cupón: {{ pedidoDisplay.cupon_id }}</span>
      </div>
      <div class="total">
        <span>Total: ${{pedidoDisplay.subtotal | number}}</span>
      </div>
    </div>
    <div class="preciosTotales" *ngIf="pedidoDisplay.token_operador">
      <div class="total">
        <span>Token: {{pedidoDisplay.token_operador | number}}</span>
      </div>
    </div>
  </div>
</div>

<div class="modal modalCancelar">
  <div class="modalInterno">
    <div class="cabezaModal">
      <a href="#" class="printModal"><i class="fas fa-print"></i></a>
      <div class="titulo">
        <h2>Alerta de cancelación</h2>
      </div>
      <a href="#" class="closeModal">
        <i class="far fa-times-circle"></i>
      </a>
    </div>
    <img src="assets/images/OXXO_logo.svg" alt="" class="logo">
    <h2>¿Por qué deseas cancelar el pedido de <span class="nombre">Juán Esteban Díaz Plazas</span></h2>
    <form action="">
      <input type="radio" name="razon" id="razon1" required>
      <label for="razon1">Razón 1 Razón 1 Razón 1 Razón 1</label>
      <input type="radio" name="razon" id="razon2">
      <label for="razon2">Razón 2 Razón 2 Razón 2 Razón 2</label>
      <input type="radio" name="razon" id="razon3">
      <label for="razon3">Razón 3 Razón 3 Razón 3 Razón 3</label>
      <input type="radio" name="razon" id="razon4">
      <label for="razon4">Otro</label>
      <textarea name="otro"></textarea>
      <div class="wrap-login100-form-btn">
        <div class="login100-form-bgbtn"></div>
        <button class="login100-form-btn">
          ACEPTAR
        </button>
      </div>
    </form>
  </div>
</div>
<div class="modal modalConfCancelado">
  <div class="modalInterno">
    <div class="cabezaModal">
      <a href="#" class="printModal"><i class="fas fa-print"></i></a>
      <div class="titulo">
        <h2>Alerta de cancelación</h2>
      </div>
      <a href="#" class="closeModal">
        <i class="far fa-times-circle"></i>
      </a>
    </div>
    <img src="assets/images/OXXO_logo.svg" alt="" class="logo">
    <h2>¡EL DOMICILIO FUE CANCELADO EXITOSAMENTE!</h2>
    <div class="buttons">
      <div class="wrap-login100-form-btn">
        <div class="login100-form-bgbtn"></div>
        <button class="login100-form-btn">
          CONTINUAR
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal modalCerrarSesion">
  <div class="modalInterno">
    <div class="cabezaModal">
      <a href="#" class="printModal"><i class="fas fa-print"></i></a>
      <div class="titulo">
        <h2>Alerta de cierre de sesión</h2>
      </div>
      <a href="#" class="closeModal">
        <i class="far fa-times-circle"></i>
      </a>
    </div>
    <img src="assets/images/OXXO_logo.svg" alt="" class="logo">
    <h2>¿Estás seguro que deseas cerrar la sesión de usuario?</h2>
    <div class="buttons">
      <div class="wrap-login100-form-btn">
        <div class="login100-form-bgbtn"></div>
        <button class="login100-form-btn">
          CANCELAR
        </button>
      </div>
      <div class="wrap-login100-form-btn">
        <div class="login100-form-bgbtn"></div>
        <button class="login100-form-btn">
          ACEPTAR
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal modalCerrarSesion" [ngStyle]="{'display': !multiSesionDisplay ? 'none' : 'block'}">
  <div class="modalInterno">
    <div class="cabezaModal">
      <a href="#" class="printModal"><i class="fas fa-print"></i></a>
      <div class="titulo">
        <h2>Alerta sesión múltiple</h2>
      </div>
      <a href="#" (click)="cerrarMultiSesion()" class="closeModal">
        <i class="far fa-times-circle"></i>
      </a>
    </div>
    <img src="assets/images/OXXO_logo.svg" alt="" class="logo">
    <h2>Han ingresado con tu misma cuenta desde otro dispositivo</h2>
    <div class="buttons">
      <div class="wrap-login100-form-btn">
        <div class="login100-form-bgbtn"></div>
        <button class="login100-form-btn" (click)="cerrarMultiSesion()">
          ACEPTAR
        </button>
      </div>
    </div>
  </div>
</div>
