export const environment = {
  production: false,
  fire: {
    apiKey: 'AIzaSyDBuBfixcvfxmRdACJEiyb3tqw8PeCjM8I',
    authDomain: 'oxxo-dev.firebaseapp.com',
    databaseURL: 'https://oxxo-dev.firebaseio.com',
    projectId: 'oxxo-dev',
    storageBucket: 'oxxo-dev.appspot.com',
    messagingSenderId: '801165575968',
    appId: '1:801165575968:web:87fd426c412362ff'
  },
  API_URL: 'https://us-central1-oxxo-dev.cloudfunctions.net/api/'
};