<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <form class="login100-form validate-form">
					<span class="login100-form-title p-b-26">
						Bienvenidos
					</span>
        <span class="login100-form-title p-b-48">
						<img src="assets/images/OXXO_logo.svg" alt="">
					</span>

        <div class="wrap-input100 validate-input" [ngClass]="{'alert-validate': error.email || error.password}"
          data-validate="Credenciales inválidas">
        </div>

        <div class="wrap-input100 validate-input">
          <input class="input100" type="text" name="email" [(ngModel)]="email" (ngModelChange)="clearError('email')">
          <span class="focus-input100" data-placeholder="Email"></span>
        </div>

        <div class="wrap-input100 validate-input">
						<span class="btn-show-pass" (click)="showEye = !showEye">
							<i class="zmdi" [ngClass]="{'zmdi-eye': !showEye, 'zmdi-eye-off': showEye}"></i>
						</span>
          <input class="input100" [attr.type]="showEye ? 'text' : 'password'" name="pass" [(ngModel)]="password"
                 (ngModelChange)="clearError('password')">
          <span class="focus-input100" data-placeholder="Password"></span>
        </div>

        <div class="container-login100-form-btn">
          <div class="wrap-login100-form-btn">
            <div class="login100-form-bgbtn"></div>
            <button class="login100-form-btn" (click)="doLogin()">
              Login
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<div id="dropDownSelect1"></div>
