import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';
import {loggedIn} from '@angular/fire/auth-guard';
import {ignore} from 'selenium-webdriver/testing';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService,
              private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('Can activate: ', this.userService);
    return new Promise(async (resolve) => {
      const isLoggedIn = await this.userService.authenticated();
      // @ts-ignore
      const componentName = next.component['name'];
      if (!isLoggedIn) {
        if (componentName !== 'LoginComponent') {
          console.log('Redirecting to /login');
          //resolve(this.router.parseUrl('/login'));
          resolve(true);
        } else {
          resolve(true);
        }

      } else {
        if (componentName === 'LoginComponent') {
          resolve(this.router.parseUrl('/dashboard'));
        } else {
          resolve(true);
        }
      }

    });

  }
}
